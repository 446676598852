









































































































































































































import AutoPostBoostingErrors from '@/components/AutoPostBoosting/AutoPostBoostingErrors.vue'
import BaseSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import BaseInputCurrency from '@/components/BaseInputCurrency.vue'
import NewUiAccordion from '@/components/NewUIComponents/NewUiAccordion.vue'
import NewUiSwitcher from '@/components/NewUIComponents/NewUiSwitcher.vue'
import ICampaignObjective from '@/models/AdsManager/ICampaignObjective'
import AdsManagerCampaignService from '@/services/api/AdsManagerCampaignService'
import PostBoostingService from '@/services/api/PostBoostingService'
import props from '@/views/AutoPostBoosting/steps/props'
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'AutoPostBoostingCampaign',
  components: { AutoPostBoostingErrors, BaseInputCurrency, NewUiAccordion, NewUiSwitcher, BaseSelect },
  mixins: [props],
  data () {
    return {
      campaignNameTemplates: null,
      campaignsObjectives: null,
      BUDGET_OPTIMIZATION_DATES: [
        {
          label: 'Total',
          value: 1
        },
        {
          label: 'Daily',
          value: 2
        }
      ],
      CAMPAIGN_BID_STRATEGY: [
        {
          label: 'Lowest cost without cap',
          value: 'LOWEST_COST_WITHOUT_CAP'
        },
        {
          label: 'Lowest cost with bid cap',
          value: 'LOWEST_COST_WITH_BID_CAP'
        }
      ]
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    async getCampaignNameTemplates (): Promise<void> {
      this.campaignNameTemplates = (await PostBoostingService.getCampaignNameTemplates(this.service)).data.data
    },
    async getCampaignObjective (): Promise<void> {
      this.campaignsObjectives = (await AdsManagerCampaignService.get_campaign_objective()).data.data
      if (this.data && this.data.objective === null) {
        this.data.objective = this.campaignsObjectives[0].id
      }
    },
    templateNameNestedDropdownClickHandler (event: PointerEvent): void {
      let possiblyParent = event.target as HTMLElement
      while (possiblyParent) {
        if (possiblyParent.classList.contains('template-name-nested-dropdown')) {
          possiblyParent.dataset.open = possiblyParent.dataset.open === 'open' ? '' : 'open'
        }
        possiblyParent = possiblyParent.parentElement
      }
    },
    selectCampaignObjective (event: ICampaignObjective): void {
      if (this.data) {
        this.data.objective = event.id
      }
    },
    switchBudgetAmount () {
      if (this.budget_amount !== null) {
        this.budget_amount = null
        this.data.budget_type = null
        this.data.bidding = null
        this.bid = null
      } else {
        this.budget_amount = 10
        this.data.budget_type = 1
        this.data.bidding = this.CAMPAIGN_BID_STRATEGY[0].value
      }
    },
    frontEndErrorsHandler () {
      let result = false
      if (!this.data.name) {
        result = true
        this.$emit('set-error', 'name', 'Name field is required!')
      }
      return result
    },
    async post (): Promise<unknown> {
      return PostBoostingService.postCampaignSettings(this.service, this.ruleId, this.data)
    }
  },
  computed: {
    name: {
      get (): string {
        this.$emit('remove-error', 'name')
        return this.data?.name || ''
      },
      set (value: string): void {
        if (this.data) {
          this.data.name = value
        }
      }
    },
    spend_limit: {
      get (): number | null {
        return this.data.spend_limit
      },
      set (value: number | null): void {
        this.data.spend_limit = value
      }
    },
    budget_amount: {
      get (): number | null {
        return this.data.budget_amount
      },
      set (value: number | null): void {
        this.data.budget_amount = value
      }
    },
    bid: {
      get (): number | null {
        return this.data.bid
      },
      set (value: number | null): void {
        this.data.bid = value
      }
    },
    currency (): string {
      return this.meta?.currency?.symbol || '?'
    },
    selectedCampaignObjective (): ICampaignObjective | undefined {
      const objective = this.data.objective
      return this.campaignsObjectives?.find(_ => _.id === objective)
    }
  },
  created () {
    if (this.ruleId) {
      this.$emit('reload-campaign-rule', () => {
        this.getCampaignNameTemplates()
        this.getCampaignObjective()
      })
    } else {
      this.addNotification({ body: 'The rule has not been created yet!', type: 'danger' })
      this.$router.replace({
        name: 'PostBoostingNewDetails',
        params: { ...this.$route.params }
      })
    }
  }
})

